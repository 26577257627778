import { isValidUrl } from '@/api/helpers';

export const mapMedia = (media, content_Type) => {
  return {
    id: media.id,
    carousel_image: media.file.content_url,
    content_type: content_Type,
    live_schedule: [],
    loading: false,
    manifest: media.manifest ?? undefined,
    media_image_file: media.media_image_file,
    timestamps: media.timestamps ?? undefined,
    title: media.title,
    video_embed: isValidUrl(media.video_embed) ? media.video_embed : undefined,
  };
};

export const mapStreamToMedia = (stream, content_Type) => {
  return {
    id: stream.id,
    carousel_image: stream.sku.carousel_image,
    content_type: content_Type,
    live_schedule: stream.live_schedule ?? [],
    loading: false,
    manifest: stream.sku.media_objects[0]?.manifest ?? undefined,
    media_image_file: stream.sku.media_objects[0]?.media_image_file,
    timestamps: stream.sku.media_objects[0]?.timestamps ?? undefined,
    title: stream.sku.title,
    video_embed: stream.video_live_src,
  };
};
