export default {
  // English
  ageGate: 'This content is for a mature audience.',
  ageGateL2: 'Please only continue if you are eligible.',
  ageGateError: `Sorry, you are not of age to view this content.`,
  ageSubmission:
    'By submitting your age you are confirming you are old enough to view this content',
  ageGateConfirm: 'Are you over {minAge}?',
  ageGateConfirmMessage:
    'This content is for a mature audience. Please verify your age to continue.',
  ageGateConfirmMinAge: 'Yes I am {minAge}+',
  ageGateDenyMinAge: 'No',
  aiChat: 'AI Chat',
  audioEffect: 'Audio Effect: ',
  autoScrollOff: 'Autoscroll off',
  autoScrollOn: 'Autoscroll on',
  autotranslate: '(Auto-translated using AI)',
  accessories: 'Accessories',
  captions: 'Captions',
  close: 'Close',
  cookieBannerTextP1: `We use cookies on this video experience to analyse user activity anonymously. By clicking "I accept" you consent to the use of these cookies. See our `,
  cookieBannerPrivacyText: 'Privacy Policy ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/en-US/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'to read more.',
  cookieBannerAccept: 'I accept',
  cookieBannerDeny: 'No thanks',
  copilot: 'Copilot',
  copy: 'Copy',
  dateOfBirth: 'Enter your date of birth',
  day: 'Day',
  description: 'Description',
  details: 'Details',
  devices: 'Devices',
  explore: 'Explore',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Game information',
  isoCode: 'en',
  keyFeatures: 'Key Features',
  language: 'Language',
  learnMore: 'Learn more',
  live: 'Live',
  matureContentMessage: 'Game art can only be viewed for this game.',
  m365: 'M365',
  month: 'Month',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  nextSlide: 'Go to next slide',
  notLive: 'This stream is not live yet.',
  openTranscript: 'Open Transcript',
  otherProducts: 'Other Products',
  outdatedBrowser:
    'Oops, it looks like your browser is out of date. Update now to watch the latest streams from Mixer.',
  playVideo: 'Play Video',
  poweredByAzure: 'Powered by Azure',
  previousSlide: 'Go to previous slide',
  products: 'Products',
  productInformation: 'Product information',
  promotions: 'Promotions',
  purchaseInfo: 'Purchase Info',
  readMore: 'Read more',
  reload: 'Reload',
  reloadToTryAgain: 'Please reload to try again',
  selectDay: 'Select Day',
  selectMonth: 'Select Month',
  selectYear: 'Select Year',
  share: 'Share',
  shareDescription: 'Share this media experience and online page with someone you know.',
  shareDialogTitle: 'Check out these amazing features available with {title}!',
  shopGame: 'Shop {title}',
  shopNow: 'Shop Now',
  shopSku: 'Shop {title}',
  showMore: 'Show More',
  showLess: 'Show Less',
  showcase: 'Showcase',
  streamEnded: 'This stream has ended. On demand content is currently being uploaded.',
  timeline: 'Timeline',
  timeStampJumpTo: 'Jump To',
  timeStampMore: 'More',
  tips: 'Tips',
  unknownError: 'Oops! Something went wrong while loading the video player',
  video: 'video',
  voice: 'Voice: ',
  view: 'View',
  watchNow: 'Watch Now',
  windows: 'Windows 11',
  year: 'Year',
};
