export default {
  // Chinese - Simplified
  ageGate: '此内容适用于成熟的受众。',
  ageGateL2: '请仅在您符合条件时继续。',
  ageGateError: `抱歉，您未达到查看此内容的年龄。`,
  ageSubmission: '通过提交您的年龄，您确认您已达到可以查看此内容的年龄。',
  ageGateConfirm: '你结束了吗 {minAge}?',
  ageGateConfirmMessage: '此内容适用于成熟的受众。请验证您的年龄以继续。',
  ageGateConfirmMinAge: '是的，我是 {minAge}+',
  ageGateDenyMinAge: '不',
  aiChat: 'AI 聊天',
  audioEffect: '音效：',
  autoScrollOff: '自动滚动关闭',
  autoScrollOn: '自动滚动开启',
  autotranslate: '（使用 AI 自动翻译）',
  accessories: '辅料',
  captions: '字幕',
  close: '关闭',
  cookieBannerTextP1: `我们在此视频体验中使用 Cookie 来匿名分析用户活动。点击“我接受”即表示您同意使用这些 饼干。查看我们的`,
  cookieBannerPrivacyText: '隐私策略',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/en-US/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: '以阅读更多内容。',
  cookieBannerAccept: '我接受',
  cookieBannerDeny: '不，谢谢',
  copilot: '副驾驶员',
  copy: '复制',
  dateOfBirth: '输入您的出生日期',
  day: '日',
  description: '描述',
  details: '详',
  devices: '设备',
  explore: '探讨',
  gamepass: 'Xbox Game Pass',
  gameInformation: '游戏信息',
  isoCode: 'zh-CN',
  keyFeatures: '主要特点',
  language: '语言',
  learnMore: '了解更多信息',
  live: '住',
  matureContentMessage: '游戏艺术只能用于此游戏。',
  m365: 'M365',
  month: '月',
  months: {
    jan: '一月 ',
    feb: '二月 ',
    mar: '三月',
    apr: '四月',
    may: '五月',
    jun: '六月',
    jul: '七月',
    aug: '八月',
    sep: '九月',
    oct: '十月',
    nov: '十一月',
    dec: '十二月',
  },
  nextSlide: '转到下一张幻灯片',
  notLive: '此流尚未上线。',
  openTranscript: '打开成绩单',
  otherProducts: '其他产品',
  outdatedBrowser: '糟糕，看起来您的浏览器已经过时了。立即更新以观看来自 Mixer 的最新流。',
  playVideo: '播放视频',
  poweredByAzure: '由 Azure 提供支持',
  previousSlide: '转到上一张幻灯片',
  products: '产品',
  productInformation: '产品信息',
  promotions: '促销',
  purchaseInfo: '购买信息',
  readMore: '阅读更多',
  reload: '重新加载',
  reloadToTryAgain: '请重新加载以重试',
  selectDay: '选择日期',
  selectMonth: '选择月份',
  selectYear: '选择年份',
  share: '共享',
  shareDescription: '与您认识的人分享此媒体体验和在线页面。',
  shareDialogTitle: '查看这些可用的惊人功能 {title}!',
  shopNow: '立即购买',
  shopGame: '店 {title}',
  shopSku: '店 {title}',
  showMore: '显示更多',
  showLess: '显示更少',
  showcase: '展示',
  streamEnded: '此流已结束。目前正在上传点播内容。',
  timeline: '时间轴',
  timeStampJumpTo: '跳转到',
  timeStampMore: '更多',
  tips: '技巧',
  unknownError: '哎呀！加载视频播放器时出错',
  video: '视频',
  voice: '声音：',
  view: '视图',
  watchNow: '立即观看',
  windows: 'Windows 11',
  year: '年',
};
