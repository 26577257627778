import { createStore } from 'vuex';
import buckets from './modules/buckets.js';
import display from './modules/display.js';
import initial from './modules/initial.js';
import i18n from './modules/i18n.js';
import user from './modules/user.js';
import api from '@/api';
import { getVideoId } from '@/api/helpers.js';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

export default createStore({
  state() {
    return {
      landing: {},
      retailer: {},
      stream: {},
      theme: {},
    };
  },
  getters: {
    getAgeRating(state) {
      return ['gamepass', 'gaming'].includes(state.display.activeTab)
        ? state.stream.age_rating
        : null;
    },

    getDynamicBuyNow(state, getters) {
      if (getters.getTimestamps) {
        const active = getters.getTimestamps.find(t => t.active);
        return active ? active.buyNowUrl : null;
      }
    },

    getPurchaseInfo(state) {
      if (state.display.activeTab === 'showcase') {
        const index = state.buckets.showcase.carousel.findIndex(
          m => m.video_embed === state.buckets.showcase.activeEmbed
        );
        return index > -1 && state.buckets.showcase.carousel[index].purchase_info
          ? state.buckets.showcase.carousel[index].purchase_info
          : false;
      } else {
        return state.stream.sku.media_objects?.length &&
          state.stream.sku.media_objects[0].purchase_info
          ? state.stream.sku.media_objects[0].purchase_info
          : false;
      }
    },

    getQrCodeUrl(state) {
      if (!state.stream.ar_glb_content_url) return false;

      const baseUrl = `https://${process.env.VUE_APP_PARENT_URL}/${state.retailer.id}/modals/qr`;
      const glbUrl = encodeURIComponent(state.stream.ar_glb_content_url);
      const usdzUrl = encodeURIComponent(state.stream.ar_usdz_content_url);
      const hdrUrl = encodeURIComponent(state.stream.ar_hdr_content_url);
      return `${baseUrl}?glb=${glbUrl}&usdz=${usdzUrl}&hdr=${hdrUrl}`;
    },

    getStreamDescription(state) {
      if (!state.stream.stream_descriptions) {
        return { description_html: '', is_auto_translated: false };
      }

      const streamDescription = state.stream.stream_descriptions.find(
        description => description.retailer_language.language.code_region === state.i18n.locale
      );

      return {
        description_html: streamDescription?.description_html ?? state.stream.description_html,
        is_auto_translated: streamDescription?.is_auto_translated ?? false,
      };
    },

    getTimestamps(state) {
      if (state.display.loadingMedia) return false;
      if (state.display.activeTab === 'showcase') {
        const index = state.buckets.showcase.carousel.findIndex(
          m => m.video_embed === state.buckets.showcase.activeEmbed
        );
        return index > -1 && state.buckets.showcase.carousel[index].timestamps?.length
          ? state.buckets.showcase.carousel[index].timestamps.filter(t => t.text)
          : false;
      } else {
        return state.stream.sku.media_objects?.length &&
          state.stream.sku.media_objects[0]?.timestamps?.length
          ? state.stream.sku.media_objects[0].timestamps.filter(t => t.text)
          : false;
      }
    },

    hasVideoManifest(state) {
      if (
        state.display.loadingMedia ||
        !state.stream.display?.video ||
        (state.display.activeTab !== 'showcase' &&
          state.display.cookiesEnabled &&
          state.stream.video_live_src) ||
        (state.display.activeTab === 'showcase' &&
          state.display.cookiesEnabled &&
          ['youtube', 'twitch'].some(str => state.buckets.showcase.activeEmbed.includes(str)))
      ) {
        return false;
      }

      return state.display.embedType === 'media'
        ? !!state.buckets.showcase.activeManifest?.url
        : !!state.stream.manifest?.url;
    },

    minAge(state) {
      return state.initial.stream.mature_content >= 0
        ? state.initial.stream.mature_content
        : state.retailer.matureContentMinAge;
    },

    showTimestamps(state, getters) {
      return (
        state.display.timestampsEnabled &&
        getters.getTimestamps &&
        !state.stream.display.matureMsg &&
        (!state.stream.status.live || !state.display.cookiesEnabled)
      );
    },

    videoTimeline(state, getters) {
      if (!getters.hasVideoManifest) return false;
      if (state.display.activeTab === 'showcase') {
        return state.buckets.showcase.activeManifest?.timeline ?? false;
      } else {
        return state.stream.manifest?.timeline ?? false;
      }
    },

    getZoovuProps(state) {
      try {
        const { domain, code, locale } = state.stream.sku.sku_zoovu_codes[state.retailer.id];
        return {
          domain,
          code,
          locale: locale ?? state.retailer.locale,
        };
      } catch (err) {
        return false;
      }
    },
  },

  mutations: {
    configureStream(state, params) {
      const { stream, mediaObjects, primaryMediaURL, updateCarousel = false } = params;
      stream.sku.media_objects = mediaObjects;

      stream.status = {
        live:
          Object.keys(stream.live_schedule).length > 0 &&
          stream.platform_details.channel &&
          state.display.showLiveStreams,
        future:
          Object.keys(stream.future_schedule).length > 0 &&
          stream.platform_details.channel &&
          state.display.showLiveStreams,
        is_past: stream.is_past || false,
        on_demand: !!primaryMediaURL,
      };

      // Set live src if stream is live
      if (stream.status.live) {
        if (stream.platform_details.platform === 'twitch' && stream.platform_details.channel) {
          stream.video_live_src = `https://player.twitch.tv/?channel=${stream.platform_details.channel}`;
        }

        if (['youtube', 'facebook'].includes(stream.platform_details.platform)) {
          stream.video_live_src = stream.platform_details.embed_url;
        }
      }

      // Bind correct feedback message
      stream.msg = stream.status.future
        ? state.i18n.messages.streamEnded
        : state.i18n.messages.notLive;

      stream.display = {
        video:
          (stream.status.live && stream.video_live_src !== null) ||
          (stream.status.on_demand && stream.video_src !== null),
        img: !!state.retailer.offline_image,
        matureMsg: stream.display.matureMsg,
        msg: !state.retailer.offline_image,
      };

      state.stream = stream;

      // Update stream info in the carousel
      if (updateCarousel) {
        const index = state.buckets[state.display.activeTab].carousel.findIndex(
          i => i.id === stream.id
        );

        state.buckets[state.display.activeTab].carousel[index] = {
          ...state.buckets[state.display.activeTab].carousel[index],
          ...stream,
        };
      }
    },

    removeIframeBlock(state) {
      state.landing.streamBlock = null;
    },

    resetTimelineState(state) {
      if (state.display.activeTab === 'showcase') {
        const timeline = state.buckets.showcase.activeManifest.timeline.map(e => ({
          ...e,
          active: false,
        }));
        state.buckets.showcase.activeManifest.timeline = timeline;
      } else {
        const timeline = state.stream.manifest.timeline.map(e => ({
          ...e,
          active: false,
        }));
        state.stream.manifest.timeline = timeline;
      }
    },

    resetTimestampState(state) {
      if (state.display.activeTab === 'showcase') {
        state.buckets.showcase.carousel[state.buckets.showcase.activeIndex].timestamps.forEach(
          t => (t.active = false)
        );
      } else {
        state.stream.sku.media_objects[0].timestamps.forEach(t => (t.active = false));
      }
    },

    setActiveTimeline(state, id) {
      if (state.display.activeTab === 'showcase') {
        const timeline = state.buckets.showcase.activeManifest.timeline.map(e => ({
          ...e,
          active: e.id === id,
        }));
        state.buckets.showcase.activeManifest.timeline = timeline;
      } else {
        const timeline = state.stream.manifest.timeline.map(e => ({
          ...e,
          active: e.id === id,
        }));
        state.stream.manifest.timeline = timeline;
      }
    },

    setActiveTimestamp(state, index) {
      const isActive = (t, i) => {
        t.active = i === index;
      };

      if (state.display.activeTab === 'showcase') {
        state.buckets.showcase.carousel[state.buckets.showcase.activeIndex].timestamps.forEach(
          isActive
        );
      } else {
        state.stream.sku.media_objects[0].timestamps.forEach(isActive);
      }
    },

    setRetailer(state, data) {
      state.retailer = data;
    },

    setStream(state, data) {
      state.stream = data;
    },

    setTheme(state, data) {
      const { stream, retailer } = data;
      const theme = stream.theme.name === 'dark' ? '#1b1a19' : '#ffffff';
      const bg = stream.theme.bg_color ? stream.theme.bg_color : theme;
      let ctaColor, shopNowColor, shopNowBg, highlight;

      if (stream.theme.shop_now_cta_bg_color) {
        shopNowBg = ctaColor = highlight = stream.theme.shop_now_cta_bg_color;
        shopNowColor = stream.theme.shop_now_cta_color;
      } else if (retailer.shop_now_cta_bg_color) {
        shopNowBg = ctaColor = highlight = retailer.shop_now_cta_bg_color;
        shopNowColor = retailer.shop_now_cta_color;
      } else {
        ctaColor = stream.theme.name === 'dark' ? '#0078d4' : '#0078d4';
        shopNowColor = theme;
        shopNowBg = stream.theme.name === 'dark' ? '#0078d4' : '#333333';
        highlight = stream.theme.name === 'dark' ? '#0078d4' : '#0078d4';
      }

      const bgRGB = bg
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));

      state.theme = {
        name: stream.theme.name,
        class: `theme--${stream.theme.name}`,
        bg,
        bgRGB,
        shopNowBg,
        shopNowColor,
        ctaText: stream.shop_now_cta,
        ctaColor,
        highlight,
      };
    },

    setLanding(state, data) {
      state.landing = data;
    },
  },
  actions: {
    async getLiveStream({ commit, state }, params) {
      const { retailerId, streamType, platform } = params;
      let stream;
      /*
      Create some DateTimes to use as our boundaries.
      We set the seconds tp 0 because Sis don't use seconds
      allowing the response to be fully cached for up to 1 minute
       */
      const startDateTime = new Date();
      startDateTime.setSeconds(0, 0);
      /*
      End date should be rounded up to the next minute to account
      for any streams that are still live mid-way through the current minute
      */
      const endDateTime = new Date(startDateTime.getTime() + 1 * 60000);
      // Get stream that started BEFORE "now" and end AFTER "now"
      // Adding pagination with itemsPerPage as 1 makes sure we only get 1 result
      const data = await api.getLiveStream(
        `&retailer=${retailerId}&schedules.localEndDate[after]=${endDateTime.toISOString()}&schedules.localStartDate[before]=${startDateTime.toISOString()}`,
        streamType,
        platform,
        state.retailer.matureContentMinAge
      );

      // Return current live stream or the stream that was live previously
      if (data) {
        stream = { ...data };
      } else {
        // Get the stream which ended last for this retailer
        const data = await api.getLiveStream(
          `&retailer=${retailerId}&schedules.localEndDate[before]=${endDateTime.toISOString()}&order[schedules.local_end_date]=desc`,
          streamType,
          platform,
          state.retailer.matureContentMinAge
        );
        if (data) {
          stream = { is_past: true, ...data };
        }
      }
      commit('setStream', stream);
    },

    async getRetailer({ commit }, id) {
      const retailer = await api.getRetailer(id);
      commit('setRetailer', retailer);
    },

    async getStream({ commit, state }, params) {
      NProgress.start();
      const { os, streamData } = params;
      let mediaObjects = [];
      let primaryMediaURL = null;
      const stream = { ...streamData };

      // Requesting media objects for the sku in the stream
      if (stream && stream.sku && stream.stream_type.name !== 'Attach') {
        mediaObjects = await api.getSkuMedia(
          stream.sku.id,
          state.i18n.country,
          !state.initial.stream && stream.stream_type.name === 'PDP'
        );
        if (mediaObjects.length) primaryMediaURL = mediaObjects[0].video_embed;
      }

      // Bind video src
      stream.video_src = primaryMediaURL;

      // commit('display/enablePurchaseInfo', stream.purchase_info_enabled);

      // Get video media if no mature content warning
      if (!stream.display.matureMsg) {
        // Get params for requests
        const videoId = stream.video_src?.includes('videoindexer')
          ? getVideoId(stream.video_src)
          : false;
        // Config for additional stream data required
        const streamRequests = {
          manifest: {
            enabled: !!videoId,
            data: null,
          },
          timestamps: {
            enabled: false,
            data: null,
          },
          purchaseInfo: {
            enabled: false,
            data: null,
          },
        };

        // Get additional data
        const data = await Promise.all(
          Object.keys(streamRequests)
            .filter(entry => streamRequests[entry].enabled)
            .map(request => {
              switch (request) {
                case 'manifest':
                  return api.getVideoManifest(videoId, state.i18n.locale, os);
                case 'timestamps':
                  return api.getTimestamps(stream.video_src, state.retailer.id, state.i18n.locale);
                case 'purchaseInfo':
                  return api.getPurchaseInfo(videoId, state.i18n.locale);
              }
            })
        );
        // Update streamRequest object with data
        let index = 0;
        for (const request in streamRequests) {
          if (!streamRequests[request].enabled) continue;
          streamRequests[request].data = data[index];
          index++;
        }

        if (streamRequests.manifest.data) {
          !state.initial.stream && state.stream.stream_type.name === 'PDP'
            ? (mediaObjects[0].manifest = streamRequests.manifest.data)
            : (stream.manifest = streamRequests.manifest.data);
        }

        if (streamRequests.timestamps.data)
          mediaObjects[0].timestamps = streamRequests.timestamps.data;

        if (streamRequests.purchaseInfo.data)
          mediaObjects[0].purchase_info = streamRequests.purchaseInfo.data;
      }

      if (
        !state.initial.stream &&
        stream.stream_type.name === 'Game Pass' &&
        stream.disable_live_stream
      )
        commit('display/disableLiveStreams');

      if (stream.stream_shop_now_ctas)
        commit('display/setShopNowCTAs', stream.stream_shop_now_ctas);

      commit('configureStream', {
        stream,
        mediaObjects,
        primaryMediaURL,
        updateCarousel: !!state.initial.stream,
      });
      NProgress.done();
    },

    async getStreamOnly({ commit, state }, id) {
      const stream = await api.getStream(id, state.retailer.matureContentMinAge);
      commit('setStream', stream);
    },

    async getLandingStream({ commit, state }, id) {
      const { landing, stream } = await api.getLandingStream(
        id,
        state.retailer.matureContentMinAge
      );
      if (landing) commit('setLanding', landing);
      if (stream) commit('setStream', stream);
    },

    postDiagnosticIssue(state, iframeIssue) {
      api.postDiagnosticIssue(iframeIssue);
    },
  },
  modules: {
    buckets,
    display,
    initial,
    i18n,
    user,
  },
});
