export default {
  // Italian
  ageGate: 'Contenuto destinato a un pubblico adulto.',
  ageGateL2: 'Puoi continuare solo se sei idoneo.',
  ageGateError: `Non hai l'età richiesta per visualizzare questo contenuto.`,
  ageSubmission: 'Inviando la tua età, confermi la tua idoneità a vedere questo contenuto',
  ageGateConfirm: 'Hai più di {minAge} anni?',
  ageGateConfirmMessage:
    'Questo contenuto è per un pubblico maturo. Verifica la tua età per continuare. ',
  ageGateConfirmMinAge: 'Sì, sono {minAge}+',
  ageGateDenyMinAge: 'No',
  aiChat: 'AI Chat',
  audioEffect: 'Effetto audio: ',
  autoScrollOff: 'Scorrimento automatico disattivato',
  autoScrollOn: 'Scorrimento automatico su',
  autotranslate: '(Tradotto automaticamente con intelligenza artificiale)',
  accessories: 'Accessoristica',
  captions: 'Didascalie',
  close: 'Chiudi',
  cookieBannerTextP1: `In questa esperienza video utilizziamo cookie per analizzare l'attività degli utenti in forma anonima. Facendo clic su Accetto, consenti l'utilizzo di tali cookie. Vedi la nostra `,
  cookieBannerPrivacyText: 'Informativa sulla privacy ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/it-it/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'per ulteriori informazioni.',
  cookieBannerAccept: 'Accetto',
  cookieBannerDeny: 'No, grazie',
  copy: 'Copiare',
  copilot: 'Copilota',
  dateOfBirth: 'Immetti la tua data di nascita',
  day: 'Giorno',
  description: 'Descrizione',
  details: 'Dettagli',
  devices: 'Dispositivi',
  explore: 'Esplorare',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Informazioni sul gioco',
  isoCode: 'it',
  keyFeatures: 'Caratteristiche Principali',
  language: 'Lingua',
  learnMore: 'Ulteriori informazioni',
  live: 'In diretta',
  matureContentMessage: 'La grafica del gioco può essere visualizzata solo per questo gioco.',
  m365: 'M365',
  month: 'Mese',
  months: {
    jan: 'Gen',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'Mag',
    jun: 'Gui',
    jul: 'Lug',
    aug: 'Ago',
    sep: 'Set',
    oct: 'Ott',
    nov: 'Nov',
    dec: 'Dic',
  },
  nextSlide: 'Vai alla slide successiva',
  notLive: 'Questa trasmissione non è ancora iniziata.',
  openTranscript: 'Apri trascrizione',
  otherProducts: 'Altri prodotti',
  outdatedBrowser:
    'Il tuo browser non è aggiornato. Aggiornalo subito per guardare le trasmissioni di Mixer più recenti.',
  playVideo: 'Guarda il video',
  previousSlide: 'Vai alla slide precedente',
  products: 'Prodotti',
  productInformation: 'Informazioni sul prodotto',
  promotions: 'Promozioni',
  poweredByAzure: 'Basato su Azure',
  purchaseInfo: `Informazioni sugli acquisti`,
  readMore: 'Scopri di più',
  reload: 'ricaricare',
  reloadToTryAgain: 'Si prega di ricaricare per riprovare',
  selectDay: 'Seleziona giorno',
  selectMonth: 'Seleziona mese',
  selectYear: 'Seleziona anno',
  share: 'Condividi',
  shareDescription:
    'Condividi questa esperienza multimediale e la pagina online con qualcuno che conosci.',
  shareDialogTitle: "Dai un'occhiata a queste incredibili funzionalità disponibili con {title}!",
  shopGame: 'Acquista {title}',
  shopNow: 'Acquista ora',
  shopSku: 'Acquista {title}',
  showMore: 'Mostra altro',
  showLess: 'Mostra meno',
  showcase: 'Vetrina',
  streamEnded:
    'Questa trasmissione è terminata. È in corso il caricamento dei contenuti su richiesta.',
  timeline: 'Linea temporale',
  timeStampJumpTo: 'Passa a',
  timeStampMore: 'Altro',
  tips: 'Suggerimenti',
  unknownError: 'Oops! Si è verificato un problema durante il caricamento del lettore video',
  video: 'video',
  voice: 'Voce: ',
  view: 'Vista',
  watchNow: 'Guarda ora',
  windows: 'Windows 11',
  year: 'Anno',
};
