const state = () => ({
  activeStream: '',
  activeTab: '',
  ageGateAutoFocus: false,
  browser: {},
  buckets: {
    showcase: {
      name: 'showcase',
      title: 'showcase',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    devices: {
      name: 'devices',
      title: 'devices',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    products: {
      name: 'products',
      title: 'products',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    windows: {
      name: 'windows',
      title: 'windows',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    m365: {
      name: 'm365',
      title: 'm365',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    gamepass: {
      name: 'gamepass',
      title: 'gamepass',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    explore: {
      name: 'explore',
      title: 'explore',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    promotions: {
      name: 'promotions',
      title: 'promotions',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    copilot: {
      name: 'copilot',
      title: 'copilot',
      enabled: false,
      tab: true,
      boundary: 0,
    },
    tips: {
      name: 'tips',
      title: 'tips',
      enabled: false,
      tab: true,
      boundary: 0,
    },
  },
  cookiesEnabled: false,
  diagnostics: {
    stream: '',
    retailerDomain: '',
    retailerUrl: '',
  },
  embedType: '',
  error: {
    hasErrored: false,
    hide: false,
    message: 'This page could not be found',
    subMessage: '',
    showButton: false,
    statusCode: 404,
  },
  isSkuInfoOpen: false,
  loadingMedia: true,
  purchaseInfoEnabled: false,
  showTimestampsOverlay: false,
  showLiveStreams: true,
  tabsVisible: true,
  timestampsEnabled: false,
  timelineAutoScroll: true,
  videoTime: null,
  videoAutoPlayInitiated: false,
  zoeAILoaded: false,
});

const getters = {
  getEnabledBuckets(state) {
    return Object.keys(state.buckets).filter(b => state.buckets[b].enabled);
  },

  streamInfoTabIndex(state) {
    return state.isStreamInfoOpen || !state.tabsVisible ? -1 : 0;
  },
};

const mutations = {
  disableLiveStreams(state) {
    state.showLiveStreams = false;
  },
  enableCookies(state) {
    state.cookiesEnabled = true;
  },
  enablePurchaseInfo(state, enable) {
    state.purchaseInfoEnabled = enable !== undefined ? enable : state.purchaseInfoEnabled;
  },
  enableTimestamps(state) {
    state.timestampsEnabled = true;
  },
  resetVideoTime(state) {
    state.videoTime = null;
  },
  setActiveStream(state, id) {
    state.activeStream = id;
  },
  setActiveTab(state, res) {
    state.activeTab = res;
  },
  setAgeGateAutoFocus(state, val) {
    state.ageGateAutoFocus = val;
  },
  setBrowserInfo(state, browser) {
    state.browser = { ...browser };
  },
  setDiagnosticData(state, id) {
    state.diagnostics.stream = `/api/streams/${id}`;
    state.diagnostics.retailerDomain = document.referrer;
  },
  setDiagnosticRetailerUrl(state, url) {
    state.diagnostics.retailerUrl = url;
  },
  setEmbedType(state, res) {
    state.embedType = res;
  },
  setStreamError(state, errorData) {
    const { statusCode, message, subMessage, showButton, hide } = errorData;
    state.error = {
      hasErrored: true,
      hide,
      message,
      subMessage,
      showButton,
      statusCode,
    };
  },
  setIsSkuInfoOpen(state, val) {
    state.isSkuInfoOpen = val;
  },
  setLoadingMedia(state, val) {
    state.loadingMedia = val;
  },
  setShopNowCTAs(state, data) {
    state.shopNowCTAs = data;
  },
  setShowTimestampsOverlay(state, showTimestampsOverlay) {
    state.showTimestampsOverlay = showTimestampsOverlay;
  },
  setTabBoundary(state, data) {
    const { name, boundary } = data;
    state.buckets[name].boundary = boundary;
  },
  setTabDisplay(state, data) {
    const { name, val } = data;
    state.buckets[name].enabled = val;
  },
  setTabState(state, data) {
    const { name, val } = data;
    state.buckets[name].tab = val;
  },
  setTabsVisibility(state, value) {
    state.tabsVisible = value;
  },
  setTimelineAutoScroll(state, toScroll) {
    state.timelineAutoScroll = toScroll;
  },
  setVideoTime(state, time) {
    state.videoTime = time;
  },
  setZoeAILoaded(state) {
    state.zoeAILoaded = true;
  },
  videoAutoPlayInitiated(state, val) {
    state.videoAutoPlayInitiated = val;
  },
  updateBucketTitle(state, data) {
    const { type, title } = data;
    state.buckets[type].title = title;
  },
};

const actions = {
  getActiveTab({ commit }, stream) {
    let res;
    switch (stream.stream_type.name) {
      case 'Game Pass':
        res = 'gamepass';
        break;
      case 'Surface':
        res = 'devices';
        break;
      case 'Attach':
        res = 'copilot';
        break;
      case 'Category':
        res = 'm365';
        break;
      default:
        res = 'showcase';
    }
    commit('setActiveTab', res);
  },

  setAttachDisplay({ dispatch }, stream) {
    const buckets = [
      {
        name: 'copilot',
        val: stream.stream_type.name === 'Attach',
      },
      {
        name: 'gamepass',
        val: stream.buckets.gamepass,
      },
      {
        name: 'm365',
        val: stream.buckets.m365,
      },
      {
        name: 'explore',
        val: stream.buckets.explore,
      },
    ];
    dispatch('setTabsDisplay', buckets);
  },

  setGamepassDisplay({ dispatch }, stream) {
    const buckets = [
      {
        name: 'gamepass',
        val: stream.stream_type.name === 'Game Pass',
      },
      {
        name: 'copilot',
        val: stream.buckets.copilot,
      },
      {
        name: 'devices',
        val: stream.buckets.devices,
      },
      {
        name: 'explore',
        val: stream.buckets.explore,
      },
    ];
    dispatch('setTabsDisplay', buckets);
  },

  setHomepageDisplay({ commit, dispatch }, stream) {
    const buckets = [
      {
        name: 'devices',
        val: stream.stream_type.name === 'Surface',
      },
      {
        name: 'copilot',
        val: stream.buckets.copilot,
      },
      {
        name: 'm365',
        val: stream.buckets.m365,
      },
      {
        name: 'explore',
        val: stream.buckets.explore,
      },
    ];
    dispatch('setTabsDisplay', buckets);
    commit('setEmbedType', 'stream');
  },

  setPdpDisplay({ dispatch }, stream) {
    const buckets = [
      {
        name: 'showcase',
        val: stream.stream_type.name === 'PDP',
      },
      {
        name: 'gamepass',
        val: stream.buckets.gamepass,
      },
      {
        name: 'copilot',
        val: stream.buckets.copilot,
      },
      {
        name: 'm365',
        val: stream.buckets.m365,
      },
      {
        name: 'windows',
        val: stream.buckets.windows,
      },
      {
        name: 'explore',
        val: stream.buckets.explore,
      },
    ];
    dispatch('setTabsDisplay', buckets);
  },

  setM365PdpDisplay({ commit, dispatch }, stream) {
    const buckets = [
      {
        name: 'showcase',
        val: stream.stream_type.name === 'PDP',
      },
      {
        name: 'products',
        val: stream.buckets.products,
      },
      {
        name: 'copilot',
        val: stream.buckets.copilot,
      },
      {
        name: 'promotions',
        val: stream.buckets.promotions,
      },
    ];
    dispatch('setTabsDisplay', buckets);
    commit('updateBucketTitle', { type: 'products', title: 'otherProducts' });
  },

  setCategoryDisplay({ dispatch }, stream) {
    const buckets = [
      {
        name: 'm365',
        val: true,
      },
      {
        name: 'products',
        val: stream.buckets.products,
      },
      {
        name: 'copilot',
        val: stream.buckets.copilot,
      },
      {
        name: 'promotions',
        val: stream.buckets.promotions,
      },
    ];
    dispatch('setTabsDisplay', buckets);
  },

  setWindowsDisplay({ commit, dispatch }, stream) {
    const buckets = [
      {
        name: 'windows',
        val: stream.stream_type.name === 'Category',
      },
      {
        name: 'showcase',
        val: stream.stream_type.name === 'PDP',
      },
      {
        name: 'devices',
        val: stream.buckets.devices,
      },
      {
        name: 'copilot',
        val: stream.buckets.copilot,
      },
      {
        name: 'tips',
        val: stream.buckets.tips,
      },
      {
        name: 'm365',
        val: stream.buckets.m365,
      },
    ];
    dispatch('setTabsDisplay', buckets);
    commit('updateBucketTitle', { type: 'showcase', title: 'windows' });
  },

  setTabsDisplay({ commit }, buckets) {
    buckets.forEach(bucket => {
      commit('setTabDisplay', { name: bucket.name, val: bucket.val });
    });
  },

  async updateDisplay({ commit }, params) {
    const { content_type, id } = params;
    commit('setEmbedType', content_type);
    commit('setActiveStream', id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
