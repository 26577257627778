export default {
  // Danish
  ageGate: 'Dette indhold er for et modent publikum.',
  ageGateL2: 'Fortsæt kun, hvis du er berettiget.',
  ageGateError: `Beklager, men du er ikke nødt til at se dette indhold.`,
  ageSubmission:
    'Ved at indsende din alder bekræfter du, at du er gammel nok til at se dette indhold',
  ageGateConfirm: 'Er du over {minAge} år?',
  ageGateConfirmMessage:
    'Dette indhold er til et modent publikum. Bekræft venligst din alder for at fortsætte.',
  ageGateConfirmMinAge: 'Ja, jeg er {minAge}+',
  ageGateDenyMinAge: 'Nej',
  aiChat: 'AI-chat',
  audioEffect: 'Lydeffekt: ',
  autoScrollOff: 'Automatisk registrering',
  autoScrollOn: 'Automatisk registrering den',
  autotranslate: '(Automatisk oversat ved hjælp af AI)',
  accessories: 'Tilbehør',
  captions: 'Billedtekster',
  close: 'Lukke',
  cookieBannerTextP1: `Vi bruger cookies på denne videooplevelse til at analysere brugeraktivitet anonymt. Ved at klikke på "Jeg accepterer" giver du samtykke til brugen af disse cookies. Se vores `,
  cookieBannerPrivacyText: 'privatlivspolitik ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/da-dk/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'for at læse mere.',
  cookieBannerAccept: 'Jeg accepterer',
  cookieBannerDeny: 'Nej tak',
  copilot: 'Copilot',
  copy: 'Eksemplar',
  dateOfBirth: 'Angiv din fødselsdato',
  day: 'Dag',
  description: 'Beskrivelse',
  details: 'Detaljer',
  devices: 'Enheder',
  explore: 'Undersøge',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Oplysninger om spil',
  isoCode: 'da',
  keyFeatures: 'Vigtigste Funktioner',
  language: 'Sprog',
  learnMore: 'Lær mere',
  live: 'Leve',
  matureContentMessage: 'Spilkunst kan kun ses for dette spil.',
  m365: 'M365',
  month: 'Måned',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'Maj',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dec',
  },
  nextSlide: 'Gå til næste slide',
  notLive: 'Denne stream er ikke live endnu.',
  openTranscript: 'Åbn udskrift',
  otherProducts: 'Andre produkter',
  outdatedBrowser:
    'Ups, det ser ud som din browser er forældet. Opdater nu for at se de nyeste streams fra Mixer.',
  playVideo: 'Afspil video',
  poweredByAzure: 'Drevet af Azure',
  previousSlide: 'Gå til forrige slide',
  products: 'Produkter',
  productInformation: 'Produkt information',
  promotions: 'Kampagner',
  purchaseInfo: 'Købsoplysninger',
  readMore: 'Læs mere',
  reload: 'Genindlæse',
  reloadToTryAgain: 'Genindlæs for at prøve igen',
  selectDay: 'Vælg dag',
  selectMonth: 'Vælg måned',
  selectYear: 'Vælg årstal',
  share: 'Aktie',
  shareDescription: 'Del denne medieoplevelse og onlineside med en person, du kender.',
  shareDialogTitle: 'Tjek disse fantastiske funktioner, der er tilgængelige med {title}!',
  shopGame: 'Køb {title}',
  shopNow: 'Køb nu',
  shopSku: 'Køb {title}',
  showMore: 'Vis mere',
  showLess: 'Vis mindre',
  showcase: 'Fremvise',
  streamEnded: 'Denne stream er afsluttet. On demand-indhold uploades i øjeblikket.',
  timeline: 'Tidslinje',
  timeStampJumpTo: 'Gå til',
  timeStampMore: 'Mere',
  tips: 'Tips',
  unknownError: 'Ups! Noget gik galt under indlæsning af videoafspilleren',
  video: 'video',
  voice: 'Stemme: ',
  view: 'Udsigt',
  watchNow: 'Se nu',
  windows: 'Windows 11',
  year: 'År',
};
