export default {
  // Polish
  ageGate: 'Ta treść jest przeznaczona wyłącznie dla dorosłych odbiorców.',
  ageGateL2: 'Kontynuuj tylko wtedy, gdy spełniasz określone wymagania wiekowe.',
  ageGateError: `Przepraszamy, nie spełniasz określonych wymagań wiekowych aby przeglądać te treści.`,
  ageSubmission:
    'Podając swój wiek, potwierdzasz, że spełniasz wymagania wiekowe aby przeglądać te treści.',
  ageGateConfirm: 'Masz ukończone {minAge} lat?',
  ageGateConfirmMessage:
    'Ta treść jest przeznaczona dla dojrzałych odbiorców. Aby kontynuować, zweryfikuj swój wiek.',
  ageGateConfirmMinAge: 'Tak, mam ukończone {minAge} lat.',
  ageGateDenyMinAge: 'Nie',
  aiChat: 'Czat z SI',
  audioEffect: 'Efekt audio: ',
  autoScrollOff: 'Automatyczne wyłączanie',
  autoScrollOn: 'Autoscroll na',
  autotranslate: '(Automatyczne tłumaczenie za pomocą AI)',
  accessories: 'Akcesoria',
  captions: 'Napisy',
  close: 'Zamknij',
  cookieBannerTextP1: `Używamy plików cookie w tym wideo, aby anonimowo analizować aktywność użytkowników. Klikając „Akceptuję”, wyrażasz zgodę na używanie plików cookie. Zobacz naszą `,
  cookieBannerPrivacyText: 'Politykę Prywatności ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/pl-PL/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'aby dowiedzieć się więcej.',
  cookieBannerAccept: 'Akceptuję',
  cookieBannerDeny: 'Nie, dziękuję',
  copilot: 'Kopilot',
  copy: 'Kopiuj',
  dateOfBirth: 'Podaj swoją datę urodzenia',
  day: 'Dzień',
  description: 'Opis',
  details: 'Detale',
  devices: 'Urządzeń',
  explore: 'Badać',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Informacje o grze',
  isoCode: 'pl',
  keyFeatures: 'Najważniejsze Cechy',
  language: 'Język',
  learnMore: 'Ucz się więcej',
  live: 'Transmisja na żywo',
  matureContentMessage: 'Grafika gry może być wyświetlana tylko dla tej gry.',
  m365: 'M365',
  month: 'Miesiąc',
  months: {
    jan: 'Sty',
    feb: 'Lut',
    mar: 'Mar',
    apr: 'Kwi',
    may: 'Maj',
    jun: 'Cze',
    jul: 'Lip',
    aug: 'Sie',
    sep: 'Wrz',
    oct: 'Paź',
    nov: 'Lis',
    dec: 'Gru',
  },
  nextSlide: 'Przejdź do kolejnej sekcji',
  notLive: 'Ta transmisja jeszcze się nie rozpoczęła',
  openTranscript: 'Otwórz transkrypcję',
  otherProducts: 'Inne produkty',
  outdatedBrowser:
    'Ups, wygląda na to, że Twoja przeglądarka jest zaktualizowana. Zaktualizuj teraz, aby oglądać najnowsze transmisje z usługi Mixer.',
  playVideo: 'Odtwórz wideo',
  poweredByAzure: 'Obsługiwane przez platformę Azure',
  previousSlide: 'Przejdź do poprzedniej sekcji',
  products: 'Produktów',
  productInformation: 'Informacje o produkcie',
  promotions: 'Promocje',
  purchaseInfo: 'Informacje o zakupie',
  readMore: 'Dowiedz się więcej',
  reload: 'Przeładować',
  reloadToTryAgain: 'Załaduj ponownie, aby spróbować ponownie',
  selectDay: 'Wybierz dzień',
  selectMonth: 'Wybierz miesiąc',
  selectYear: 'Wybierz rok',
  share: 'Dzielić',
  shareDescription:
    'Podziel się tym doświadczeniem multimedialnym i stroną online z kimś, kogo znasz.',
  shareDialogTitle: 'Sprawdź te niesamowite funkcje dostępne w {title}!',
  shopGame: '{title} Kup',
  shopNow: 'Kup teraz',
  shopSku: '{title} Kup',
  showMore: 'Pokaż więcej',
  showLess: 'Pokaż mniej',
  showcase: 'Wyświetlanie',
  streamEnded: 'Ta transmisja się zakończyła. Obecnie trwa przesyłanie treści na żądanie.',
  timeline: 'Oś czasu',
  timeStampJumpTo: 'Przejdź do',
  timeStampMore: 'Więcej',
  tips: 'Wskazówki',
  unknownError: 'Oops! Coś poszło nie tak podczas ładowania odtwarzacza wideo',
  video: 'wideo',
  voice: 'Głos: ',
  view: 'Widok',
  watchNow: 'Oglądaj teraz',
  windows: 'Windows 11',
  year: 'Rok',
};
