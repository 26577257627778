export default {
  // Korean
  ageGate: '이 콘텐츠는 성인 대상입니다.',
  ageGateL2: '적격인 경우에만 계속 진행하여 주시기 바랍니다.',
  ageGateError: `죄송합니다. 이 콘텐츠를 볼 수 있는 연령이 아닙니다.`,
  ageSubmission: '연령을 제출하여 이 콘텐츠를 보기 충분한 나이라는 것을 확인하세요.',
  ageGateConfirm: '{minAge}세 이상이신가요?',
  ageGateConfirmMessage: '이 콘텐츠는 성숙한 청중을위한 것입니다. 계속하려면 나이를 확인하십시오.',
  ageGateConfirmMinAge: '네, 저는 {minAge}+',
  ageGateDenyMinAge: '아니요',
  aiChat: 'AI 채팅',
  audioEffect: '오디오 효과: ',
  autoScrollOff: '자동 스크롤 끄기',
  autoScrollOn: '자동 스크롤 켜기',
  autotranslate: '(AI를 사용하여 자동 번역)',
  accessories: '액세서리',
  captions: '캡션',
  close: '닫기',
  cookieBannerTextP1: `사용자 활동을 익명으로 분석하기 위해 비디오 경험에 쿠키를 사용합니다. "수락"을 클릭하면 이러한 쿠키 사용에 동의한다는 뜻입니다. 개인 정보 `,
  cookieBannerPrivacyText: '보호 정책 ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/ko-kr/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: '에서 자세한 사항을 확인하세요.',
  cookieBannerAccept: '수락',
  cookieBannerDeny: '안 함',
  copilot: '동승자',
  copy: '부',
  dateOfBirth: '생년월일을 입력하세요',
  day: '하루',
  description: '설명',
  details: '세부',
  devices: '장치',
  explore: '탐색',
  gamepass: 'Xbox Game Pass',
  gameInformation: '게임 정보',
  isoCode: 'ko',
  keyFeatures: '키특성',
  language: '언어',
  learnMore: '더 알아보기',
  live: '라이브',
  matureContentMessage: '게임 아트는 이 게임에서만 볼 수 있습니다.',
  m365: 'M365',
  month: '달',
  months: {
    jan: '일월',
    feb: '이월',
    mar: '삼월',
    apr: '사월',
    may: '오월',
    jun: '유월',
    jul: '칠월',
    aug: '팔월',
    sep: '구월',
    oct: '시월',
    nov: '십일월',
    dec: '십이월',
  },
  nextSlide: '다음 슬라이드로 이동',
  notLive: '이 스트리밍은 아직 라이브 상태가 아닙니다.',
  openTranscript: '공개 성적 증명서',
  otherProducts: '다른 제품',
  outdatedBrowser:
    '이런, 오래된 브라우저를 사용 중입니다. 지금 업데이트하고 Mixer에서 라이브 스트리밍을 시청하세요.',
  playVideo: '비디오 재생',
  previousSlide: '이전 슬라이드로 이동',
  poweredByAzure: 'Azure기반',
  products: '제품',
  productInformation: '제품 정보',
  promotions: '프로 모션',
  purchaseInfo: '구매 정보',
  readMore: '자세히 보기',
  reload: '새로고침',
  reloadToTryAgain: '다시 시도하려면 새로고침하세요.',
  selectDay: '선택 일',
  selectMonth: '월 선택',
  selectYear: '연도 선택',
  share: '공유',
  shareDescription: '이 미디어 경험 및 온라인 페이지를 아는 사람과 공유합니다.',
  shareDialogTitle: '{title}에서 사용할 수 있는 놀라운 기능을 확인해 보세요!',
  shopGame: '{title} 구매',
  shopNow: '지금 쇼핑하기',
  shopSku: '{title} 구매',
  showMore: '더 보기',
  showLess: '숨기기',
  showcase: '쇼케이스',
  streamEnded: '스트리밍이 끝났습니다. 온디맨드 콘텐츠가 현재 업로드 중입니다.',
  timeline: '타임라인',
  timeStampJumpTo: '다음으로 이동',
  timeStampMore: '자세히',
  tips: '팁',
  unknownError: '이런! 비디오 플레이어를로드하는 동안 문제가 발생했습니다.',
  video: '동영상',
  voice: '목소리: ',
  view: '보기',
  watchNow: '지금 시청하기',
  windows: 'Windows 11',
  year: '년',
};
