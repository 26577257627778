export const normalizeZoovuData = sku_zoovu_codes => {
  const normalizedCodes = {};
  sku_zoovu_codes.forEach(zoovu => {
    normalizedCodes[zoovu.retailer.id] = {
      id: zoovu.retailer.id,
      domain: zoovu.domain,
      code: zoovu.code,
      locale: zoovu.locale,
    };
  });
  return normalizedCodes;
};
