export default {
  // French Canadian
  ageGate: 'Ce contenu s’adresse à un auditoire adulte.',
  ageGateL2: 'Veuillez continuer uniquement si vous avez l’âge requis.',
  ageGateError: `Désolé pour ceux qui n’ont pas l’âge requis pour voir ce contenu.`,
  ageSubmission:
    'En soumettant votre âge, vous confirmez que vous êtes assez vieux pour voir ce contenu',
  ageGateConfirm: 'Avez-vous plus de {minAge} ans? ',
  ageGateConfirmMessage:
    'Ce contenu est destiné à un public mature. S’il vous plaît vérifier votre âge pour continuer. ',
  ageGateConfirmMinAge: 'Oui, je suis {minAge}+',
  ageGateDenyMinAge: 'Non',
  aiChat: 'AI Chat',
  audioEffect: 'Effet audio: ',
  autoScrollOff: 'Autoscroll désactivé',
  autoScrollOn: 'Autoscroll sur',
  autotranslate: '(Traduit automatiquement à l’aide de l’IA)',
  accessories: 'Accessoires',
  captions: 'Légendes',
  close: 'Fermer',
  cookieBannerTextP1: `Nous utilisons des témoins dans cette vidéo pour analyser l’activité des utilisateurs de manière anonyme. En cliquant sur J’accepte, vous acceptez l’utilisation de ces témoins. Reportez-vous à notre `,
  cookieBannerPrivacyText: 'Politique de confidentialité ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/en-US/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'pour en savoir plus.',
  cookieBannerAccept: 'J’accepte',
  cookieBannerDeny: 'Non merci',
  copilot: 'Copilote',
  copy: 'Copie de l’exemplaire',
  dateOfBirth: 'Entrez votre date de naissance',
  day: 'Jour',
  description: 'Description',
  details: 'Détails',
  devices: 'Appareils',
  explore: 'Explorer',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Renseignements sur le jeu',
  isoCode: 'fr',
  keyFeatures: 'Principales Caractéristiques',
  language: 'Langue officielle',
  learnMore: 'En savoir plus',
  live: 'Live',
  matureContentMessage: 'L’art du jeu ne peut être consulté que pour ce jeu.',
  m365: 'M365',
  month: 'Mois',
  months: {
    jan: 'Jan',
    fév: 'Fév',
    mar: 'Mar',
    avr: 'Avr',
    mai: 'Mai',
    jui: 'Jui',
    Juil: 'Juil',
    août: 'août',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    déc: 'Déc',
  },
  nextSlide: 'Allez à la diapositive suivante',
  notLive: 'Cette diffusion n’est pas encore en ligne.',
  openTranscript: 'Ouvrir la transcription',
  otherProducts: 'Autres produits',
  outdatedBrowser:
    'Oups, il semble que votre navigateur n’est pas à jour. Faites les dernières mises à jour pour regarder les diffusions sur Mixer.',
  playVideo: 'Lire la vidéo',
  previousSlide: 'Allez à la diapositive précédente',
  products: 'Produits',
  productInformation: 'Informations sur le produit',
  promotions: 'Promotions',
  poweredByAzure: 'Propulsé par Azure',
  purchaseInfo: 'Information d’achat',
  readMore: 'En savoir plus',
  reload: 'recharger',
  reloadToTryAgain: 'S’il vous plaît recharger pour réessayer',
  selectDay: 'Sélectionner le jour',
  selectMonth: 'Sélectionnez le mois',
  selectYear: 'Sélectionnez l’année',
  share: 'Partager',
  shareDescription:
    'Partagez cette expérience médiatique et cette page en ligne avec quelqu’un que vous connaissez.',
  shareDialogTitle: 'Découvrez ces fonctionnalités étonnantes disponibles avec {title}!',
  shopGame: 'Boutique {title}',
  shopNow: 'Magasiner maintenant',
  shopSku: 'Boutique {title}',
  showMore: 'Afficher plus',
  showLess: 'Afficher moins',
  showcase: 'Présentation',
  streamEnded: 'Cette diffusion est terminée. Le contenu à la demande est actuellement téléchargé.',
  timeline: 'Chronologie',
  timeStampJumpTo: 'Allez à',
  timeStampMore: 'Plus',
  tips: 'Conseils',
  unknownError: 'Oups! Quelque chose s’est mal passé lors du chargement du lecteur vidéo',
  video: 'vidéo',
  voice: 'Voix : ',
  view: 'Voir',
  watchNow: 'Regarder maintenant',
  windows: 'Windows 11',
  year: 'Année',
};
