export default {
  // Portuguese
  ageGate: 'Este conteúdo é para um público maduro.',
  ageGateL2: 'Por favor, continue se for elegível.',
  ageGateError: `Desculpe, você não tem idade para assistir este conteúdo.`,
  ageSubmission: 'Desculpe, não tem idade para ver este conteúdo.',
  ageGateConfirm: 'Já ultrapassou o {minAge}?',
  ageGateConfirmMessage:
    'Este conteúdo é para um público maduro. Por favor, verifique a sua idade para continuar.',
  ageGateConfirmMinAge: 'Sim, sou {minAge}+',
  ageGateDenyMinAge: 'Não',
  aiChat: 'Bate-papo com IA',
  audioEffect: 'Efeito áudio: ',
  autoScrollOff: 'Autoscroll off',
  autoScrollOn: 'Autoscroll em',
  autotranslate: '(Traduzido automaticamente através de IA)',
  accessories: 'Acessórios',
  captions: 'Legendas',
  close: 'Fechar',
  cookieBannerTextP1: `Utilizamos cookies nesta experiência de vídeo para analisar a atividade do utilizador de forma anónima. Ao clicar em "Eu aceito" você concorda com a utilização destes cookies. Ver o nosso `,
  cookieBannerPrivacyText: 'Política de Privacidade',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/pt-PT/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'para ler mais.',
  cookieBannerAccept: 'Eu aceito',
  cookieBannerDeny: 'Não, obrigado',
  copilot: 'Copiloto',
  copy: 'Cópia',
  dateOfBirth: 'Insira a sua data de nascimento',
  day: 'Dia',
  description: 'Descrição',
  details: 'Detalhes',
  devices: 'Dispositivos',
  explore: 'Explorar',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Informações do jogo',
  isoCode: 'pt',
  keyFeatures: 'Principais Características',
  language: 'Idioma',
  learnMore: 'Saiba mais',
  live: 'Ao vivo',
  matureContentMessage: 'A arte do jogo só pode ser vista para este jogo.',
  m365: 'M365',
  month: 'Mês',
  months: {
    jan: 'Jan',
    feb: 'Fev',
    mar: 'Mar',
    apr: 'Abr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Ago',
    sep: 'Set',
    oct: 'Out',
    nov: 'Nov',
    dec: 'Dez',
  },
  nextSlide: 'Ir para o próximo slide',
  notLive: 'Este riacho ainda não está ao vivo.',
  openTranscript: 'Transcrição aberta',
  otherProducts: 'Outros Produtos',
  outdatedBrowser:
    'Parece que o seu navegador está desatualizado. Atualize agora para ver os mais recentes streams do Mixer.',
  playVideo: 'Reproduzir vídeo',
  poweredByAzure: 'Movido por Azure',
  previousSlide: 'Ir para o slide anterior',
  products: 'Produtos',
  productInformation: 'Informação do produto',
  promotions: 'Promoções',
  purchaseInfo: 'Informação de Compra',
  readMore: 'Ler mais',
  reload: 'recarregar',
  reloadToTryAgain: 'Por favor, recarregue para tentar novamente',
  selectDay: 'Dia de Seleção',
  selectMonth: 'Mês selecionado',
  selectYear: 'Ano selecionado',
  share: 'Partilhar',
  shareDescription: 'Partilhe agora e ajude alguém a descobrir o seu próximo jogo favorito.',
  shareDialogTitle: 'Veja este grande {title} vídeo!',
  shopGame: 'Comprar {title}',
  shopNow: 'Compre agora',
  shopSku: 'Comprar {title}',
  showMore: 'Mostrar mais',
  showLess: 'Mostrar Menos',
  showcase: 'Vitrine',
  streamEnded: 'Este riacho terminou. A pedido, o conteúdo está atualmente a ser carregado.',
  timeline: 'Linha do tempo',
  timeStampJumpTo: 'Salta para',
  timeStampMore: 'Mais',
  tips: 'Dicas',
  unknownError: 'Ops! Algo correu mal durante o carregamento do leitor de vídeo',
  video: 'video',
  voice: 'Voz: ',
  view: 'Ver',
  watchNow: 'Assista agora',
  windows: 'Windows 11',
  year: 'Ano',
};
