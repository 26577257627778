export default {
  // Swedish
  ageGate: 'Det här innehållet är avsett för en mogen publik.',
  ageGateL2: 'Fortsätt endast om du är berättigad.',
  ageGateError: `Du är tyvärr inte tillräckligt gammal för att se det här innehållet.`,
  ageSubmission:
    'Genom att ange din ålder bekräftar du att du är gammal nog att se det här innehållet',
  ageGateConfirm: 'Är du över {minAge} år?',
  ageGateConfirmMessage:
    'Detta innehåll är för en mogen publik. Kontrollera din ålder för att fortsätta.',
  ageGateConfirmMinAge: 'Ja, jag är {minAge}+',
  ageGateDenyMinAge: 'Nej',
  aiChat: 'AI-chatt',
  audioEffect: 'Ljudeffekt: ',
  autoScrollOff: 'Autoscrolla på',
  autoScrollOn: 'Automatisk avskrivning',
  autotranslate: '(Automatiskt översatt med AI)',
  accessories: 'Tillbehör',
  captions: 'Bildtexter',
  close: 'Stäng',
  cookieBannerTextP1: `Vi använder cookies för den här videofunktionen så att vi kan analysera användaraktiviteten anonymt. Genom att klicka på "Jag accepterar" godkänner du användningen av dessa cookies. Se vår `,
  cookieBannerPrivacyText: 'Sekretesspolicy ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/sv-se/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'om du vill veta mer.',
  cookieBannerAccept: 'Jag accepterar',
  cookieBannerDeny: 'Nej tack',
  copilot: 'Copilot',
  copy: 'Kopia',
  dateOfBirth: 'Ange ditt födelsedatum',
  day: 'Dag',
  description: 'Beskrivning',
  details: 'Information',
  devices: 'Enheter',
  explore: 'Utforska',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Spelinformation',
  isoCode: 'sv',
  keyFeatures: 'Viktiga Funktioner',
  language: 'Språk',
  learnMore: 'Lära sig mer',
  live: 'Live',
  matureContentMessage: 'Spelkonst kan bara ses för det här spelet.',
  m365: 'M365',
  month: 'Månad',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'Maj',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dec',
  },
  nextSlide: 'Gå till nästa bild',
  notLive: 'Den här direktuppspelningen har inte börjat ännu.',
  openTranscript: 'Öppna transkription',
  otherProducts: 'Andra produkter',
  outdatedBrowser:
    'Oj, din webbläsare verkar vara föråldrad. Uppdatera nu om du vill se de senaste direktuppspelningarna från Mixer.',
  playVideo: 'Spela upp video',
  previousSlide: 'Gå till föregående bild',
  poweredByAzure: 'Drivs av Azure',
  products: 'Produkter',
  productInformation: 'Produktinformation',
  promotions: 'Kampanjer',
  purchaseInfo: 'Inköpsinformation',
  readMore: 'Läs mer',
  reload: 'Reload',
  reloadToTryAgain: 'Ladda om för att försöka igen',
  selectDay: 'Välj dag',
  selectMonth: 'Välj månad',
  selectYear: 'Välj år',
  share: 'aktie',
  shareDescription: 'Dela den här medieupplevelsen och onlinesidan med någon du känner.',
  shareDialogTitle: 'Kolla in dessa fantastiska funktioner som finns med en {title}!',
  shopGame: 'Köp {title}',
  shopNow: 'Handla nu',
  shopSku: 'Köp {title}',
  showMore: 'Visa mer',
  showLess: 'Visa mindre',
  showcase: 'Visa',
  streamEnded:
    'Den här direktuppspelningen har avslutats. Innehåll på begäran håller på att överföras.',
  timeStampJumpTo: 'Gå till',
  timeStampMore: 'Mer',
  timeline: 'Tidslinje',
  tips: 'Tips',
  unknownError: 'Hoppsan! Något gick fel när videospelaren laddades',
  video: 'video',
  voice: 'Röst: ',
  view: 'Utsikt',
  watchNow: 'Titta nu',
  windows: 'Windows 11',
  year: 'År',
};
