export default {
  // French
  ageGate: 'Ce contenu est destiné à un public averti.',
  ageGateL2: 'Ne continuez que si vous avez au moins 18 ans.',
  ageGateError: `Désolé, vous n’avez pas l’âge requis pour accéder à ce contenu.`,
  ageSubmission:
    'En indiquant votre date de naissance, vous certifiez être en âge de voir ce contenu',
  ageGateConfirm: 'Vous avez plus de {minAge} ans?',
  ageGateConfirmMessage:
    'Ce contenu s’adresse à un public mature. Veuillez vérifier votre âge pour continuer. ',
  ageGateConfirmMinAge: 'Oui, je suis {minAge}+',
  ageGateDenyMinAge: 'Non',
  aiChat: 'AI Chat',
  audioEffect: 'Effet audio: ',
  autoScrollOff: 'Défilement automatique désactivé',
  autoScrollOn: 'Défilement automatique sur',
  autotranslate: '(Traduction automatique à l’aide de l’IA)',
  accessories: 'Accessoires',
  captions: 'Légendes',
  close: 'Fermer',
  cookieBannerTextP1: `Nous utilisons des cookies sur cette expérience vidéo pour analyser l'activité des utilisateurs de manière anonyme. En cliquant sur "J'accepte", vous consentez à l'utilisation de ces cookies. Pour en savoir plus, consultez notre `,
  cookieBannerPrivacyText: 'politique de confidentialité',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/fr-FR/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: '.',
  cookieBannerAccept: 'J’accepte',
  cookieBannerDeny: 'Non merci',
  copilot: 'Copilote',
  copy: 'Copie',
  day: 'Jour',
  dateOfBirth: 'Entrez votre date de naissance',
  description: 'Description',
  details: 'Détails',
  devices: 'Famille Surface',
  explore: 'Explorer',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Informations sur le jeu',
  isoCode: 'fr',
  keyFeatures: 'Caractéristiques Principales',
  language: 'Langue',
  learnMore: 'Pour en savoir plus',
  live: 'Vivre',
  matureContentMessage: 'L’art du jeu ne peut être vu que pour ce jeu.',
  m365: 'Microsoft 365',
  month: 'Mois',
  months: {
    jan: 'Jan',
    feb: 'Fév',
    mar: 'Mar',
    apr: 'Avr',
    may: 'Mai',
    jun: 'Jui',
    jul: 'Jui',
    aug: 'Aou',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Déc',
  },
  nextSlide: 'Aller à la diapositive suivante',
  notLive: 'pas en direct.',
  openTranscript: 'Transcription ouverte',
  otherProducts: 'Autres produits',
  outdatedBrowser: `Oops, il semble que votre explorateur n'est pas à jour. Effectuez la mise à jour pour regarder les streams depuis Mixer`,
  playVideo: 'Lire la vidéo',
  poweredByAzure: 'Propulsé par Azure',
  previousSlide: 'Aller à la diapositive précédente',
  products: 'Produits',
  productInformation: 'Informations sur le produit',
  promotions: 'Promotions',
  purchaseInfo: 'Informations d’achat',
  readMore: 'Lire plus',
  reload: 'recharger',
  reloadToTryAgain: 'Veuillez recharger pour réessayer',
  selectDay: 'Sélectionner le jour',
  selectMonth: 'Sélectionner le mois',
  selectYear: 'Sélectionnez l’année',
  share: 'Partager',
  shareDescription:
    'Partagez cette expérience médiatique et cette page en ligne avec quelqu’un que vous connaissez.',
  shareDialogTitle: 'Découvrez ces fonctionnalités étonnantes disponibles avec {title}!',
  shopGame: 'Acheter {title}',
  shopNow: 'Acheter maintenant',
  shopSku: 'Acheter {title}',
  showMore: 'Montre Plus',
  showLess: 'Montrer Moins',
  showcase: 'Présentation',
  streamEnded: 'La diffusion est terminée. Le contenu à la demande est en cours de téléchargement.',
  timeline: 'Chronologie',
  timeStampJumpTo: 'Sautez à',
  timeStampMore: 'Plus',
  tips: 'Conseils',
  unknownError: 'Oups! Quelque chose s’est mal passé lors du chargement du lecteur vidéo',
  video: 'video',
  voice: 'Voix: ',
  view: 'Vue',
  watchNow: 'Regarder maintenant',
  windows: 'Windows 11',
  year: 'Année',
};
