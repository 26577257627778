export default {
  // Arabic
  ageGate: 'هذا المحتوى مخصص للبالغين.',
  ageGateL2: 'يرجى المتابعة فقط إذا كنت مؤهلا.',
  ageGateError: `عذرا، أنت لم تبلغ السن المناسب لمشاهدة هذا المحتوى`,
  ageSubmission: 'من خلال إرسال عمرك ، فإنك تؤكد أنك كبير بما يكفي لمشاهدة هذا المحتوى',
  ageGateConfirm: 'هل أنت فوق {minAge} عاما؟',
  ageGateConfirmMessage: 'هذا المحتوى مخصص للبالغين. يرجى المتابعة فقط إذا كنت مؤهلا.',
  ageGateConfirmMinAge: 'هل أنت فوق {minAge} عاما؟',
  ageGateDenyMinAge: 'لا',
  aiChat: 'الذكاء الاصطناعي دردشة',
  audioEffect: 'تأثير الصوت: ',
  autoScrollOff: 'إيقاف التمرير التلقائي',
  autoScrollOn: 'تشغيل التمرير التلقائي',
  autotranslate: '(الترجمة التلقائية باستخدام الذكاء الاصطناعي)',
  accessories: 'اكسسوارات',
  captions: 'التسميات التوضيحية',
  close: 'اغلق',
  cookieBannerTextP1: `نحن نستخدم ملفات تعريف الارتباط في تجربة الفيديو هذه لتحليل نشاط المستخدم بشكل مجهول. بالنقر فوق "أوافق" ، فإنك توافق على استخدام ملفات تعريف الارتباط هذه. شاهد موقعنا `,
  cookieBannerPrivacyText: 'سياسة الخصوصية ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/en-US/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'لقراءة المزيد.',
  cookieBannerAccept: 'أوافق',
  cookieBannerDeny: 'لا، شكراً',
  copilot: 'مساعد الطيار',
  copy: 'نسخ',
  dateOfBirth: 'أدخل تاريخ ميلادك',
  day: 'يوم',
  description: 'وصف',
  details: 'التفاصيل',
  devices: 'الاجهزه',
  explore: 'اكتشف',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'معلومات اللعبة',
  isoCode: 'ar',
  keyFeatures: 'الميزات الرئيسية',
  language: 'اللغة',
  learnMore: 'التعرف على المزيد',
  live: 'مباشر',
  matureContentMessage: 'لا يمكن مشاهدة فن اللعبة إلا لهذه اللعبة',
  m365: 'M365',
  month: 'شهر',
  months: {
    jan: 'يناير',
    feb: 'فبراير',
    mar: 'مارس',
    apr: 'أبريل',
    may: 'مايو',
    jun: 'يونيو',
    jul: 'يوليو',
    aug: 'أغسطس',
    sep: 'سبتمبر',
    oct: 'أكتوبر',
    nov: 'نوفمبر',
    dec: 'ديسمبر',
  },
  nextSlide: 'الانتقال إلى الشريحة التالية',
  notLive: 'هذا البث ليس مباشرا بعد.',
  openTranscript: 'نص مفتوح',
  otherProducts: 'منتجات أخرى',
  outdatedBrowser: 'عفوا ، يبدو أن متصفحك قديم. قم بالتحديث الآن لمشاهدة أحدث التدفقات من Mixer.',
  playVideo: 'تشغيل الفيديو',
  poweredByAzure: 'مدعوم من Azure',
  previousSlide: 'الانتقال إلى الشريحة السابقة',
  products: 'منتجات',
  productInformation: 'معلومات المنتج',
  promotions: 'الترقيات',
  purchaseInfo: 'معلومات الشراء',
  readMore: 'إقرأ المزيد',
  reload: 'تحديث',
  reloadToTryAgain: 'يرجى إعادة التحميل للمحاولة مرة أخرى',
  selectDay: 'اختر اليوم',
  selectMonth: 'اختر الشهر',
  selectYear: 'اختر السنة',
  share: 'مشاركة',
  shareDescription: 'شارك هذه التجربة الإعلامية والصفحة عبر الإنترنت مع شخص تعرفه',
  shareDialogTitle: 'تحقق من هذه الميزات المدهشة المتاحة مع {title}!',
  shopGame: 'بوكس {title}',
  shopNow: 'اشتر الآن',
  shopSku: 'بوكس {title}',
  showMore: 'اظهار المزيد',
  showLess: 'عرض أقل',
  showcase: 'عرض',
  streamEnded: 'انتهى هذا البث. يتم حاليا تحميل المحتوى للمشاهدة حسب الطلب.',
  timeline: 'الجدول الزمني',
  timeStampJumpTo: 'انتقل إلى',
  timeStampMore: 'أكثر',
  tips: 'النصائح',
  unknownError: 'عفوا! حدث خطأ ما أثناء تحميل مشغل الفيديو',
  video: 'فيديو',
  voice: 'صوت: ',
  view: 'مشاهدة ',
  watchNow: 'شاهد الان',
  windows: 'Windows 11',
  year: 'سنة',
};
